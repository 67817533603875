<template>
    <div class="layout">
        <div class="menu" :class="{collapseMenu:isCollapse}">
            <el-menu :default-active='$route.path' class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose"
                     background-color="#393D49" text-color="#ffffff" active-text-color="#ffffff" router
                     :collapse="isCollapse">
                <el-menu-item index="/door_home">
                    <i class="el-icon-s-home"></i>
                    <span slot="title">主页</span>
                </el-menu-item>
                <el-menu-item index="/door_device">
                    <i class="el-icon-monitor"></i>
                    <span slot="title">设备管理</span>
                </el-menu-item>
                <el-menu-item index="/door_person">
                    <i class="el-icon-s-custom"></i>
                    <span slot="title">人员管理</span>
                </el-menu-item>
                <el-menu-item index="/door_record">
                    <i class="el-icon-coin"></i>
                    <span slot="title">记录管理</span>
                </el-menu-item>
                <el-menu-item index="/door_openBoxRecord">
                    <i class="el-icon-coin"></i>
                    <span slot="title">控箱记录</span>
                </el-menu-item>
                <el-menu-item index="/useful_box">
                    <i class="el-icon-coin"></i>
                    <span slot="title">可用箱子</span>
                </el-menu-item>
            </el-menu>
        </div>
        <!-- 右侧区域 -->
        <div class="right-content">
            <!-- 头部 -->
            <div class="header">
                <i v-if="!isCollapse" class="el-icon-s-fold" @click="changeCollapse()"></i>
                <i v-else class="el-icon-s-unfold" @click="changeCollapse()"></i>
                <div class="systemName">{{companyName}}</div>
                <div class="user-content">
                    <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link">
                            账户
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="userInfo">账户信息</el-dropdown-item>
                            <el-dropdown-item command="exist">退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
            <!-- 内容区域 -->
            <div class="content">
                <router-view/>
            </div>
        </div>
        <el-dialog title="账户信息" :visible.sync="userInfoDialogVisible" width="25%" :before-close="handleClose"
                   :modal-append-to-body="false">
            <span>
                <el-descriptions>
                    <el-descriptions-item label="用户名称">{{user.userName}}</el-descriptions-item>
                </el-descriptions>
                <el-descriptions>
                    <el-descriptions-item label="公司名称">{{user.companyName}}</el-descriptions-item>
                </el-descriptions>
                <el-descriptions>
                    <el-descriptions-item label="公司地址">{{user.companyAddress}}</el-descriptions-item>
                </el-descriptions>
                <el-descriptions>
                    <el-descriptions-item label="公司电话">{{user.companyPhone}}</el-descriptions-item>
                </el-descriptions>
            </span>
            <div style="width: 100%;display: flex" slot="footer" class="dialog-footer">
                <el-button style="flex: 1" type="warning" @click="updateUserNameOrPasswordDialogVisible=true">修改用户密码</el-button>
                <el-button style="flex: 1" type="primary" @click="updateCompanyInformationDialogVisible=true">修改单位信息</el-button>
            </div>
        </el-dialog>

        <el-dialog title="修改用户名称和密码" :visible.sync="updateUserNameOrPasswordDialogVisible" width="25%"
                   :before-close="handleClose"
                   :modal-append-to-body="false">
            <span>
                <el-input placeholder="用户名" v-model="userName" maxlength="20" type="text" show-word-limit>
                </el-input>

                <el-input placeholder="新密码" v-model="newPassword" maxlength="20" type="text" show-word-limit
                          style="margin-top: 20px;">
                </el-input>

                <el-input placeholder="确认密码" v-model="affirmPassword" maxlength="20" type="text" show-word-limit
                          style="margin-top: 20px;">
                </el-input>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="updateUserNameOrPassword">修改</el-button>
            </span>
        </el-dialog>
        <el-dialog title="修改单位信息" :visible.sync="updateCompanyInformationDialogVisible" width="25%"
                   :before-close="handleClose"
                   :modal-append-to-body="false">
            <span>
                <el-input placeholder="公司名称" v-model="companyName" maxlength="30" type="text" show-word-limit>
                </el-input>

                <el-input placeholder="公司地址" v-model="companyAddress" maxlength="30" type="text" show-word-limit
                          style="margin-top: 20px;">
                </el-input>

                <el-input placeholder="公司电话" v-model="companyPhone" maxlength="13" type="text" show-word-limit
                          style="margin-top: 20px;">
                </el-input>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="updateCompanyInformation">修改</el-button>
            </span>
        </el-dialog>
    </div>

</template>


<script>
    export default {
        data() {
            return {
                activeIndex: 'home',
                title: '科发智能',
                isCollapse: false,
                logoImage: '../../assets/logo.png',
                userInfoDialogVisible: false,
                updateUserNameOrPasswordDialogVisible: false,
                updateCompanyInformationDialogVisible: false,
                user: {},
                userId:0,
                userName: '',
                newPassword: '',
                affirmPassword: '',
                companyName: '',
                companyAddress: '',
                companyPhone: ''
            }
        },
        mounted() {
            this.getUserInfo()

        },
        methods: {
            handleOpen(key, keyPath) {
                console.log(key, keyPath);
            },
            changeCollapse() {
                this.isCollapse = !this.isCollapse
            },
            handleSelect(key, keyPath) {
                console.log(key, keyPath);
            },
            handleClose(done) {
                done()
            },
            handleCommand(command) {
                if (command === "userInfo") {
                    this.userInfoDialogVisible = true
                }
                if (command === "exist") {
                    this.exist()
                }
            },
            exist() {
                this.$confirm('此操作将退出登录, 是否继续?', '注销登录!', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.logout()
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消注销'
                    });
                });
            },
            logout() {
                setTimeout(() => {
                    axios.get('/logout')
                        .then((res) => {
                            if (res.data.code === 1) {
                                this.$message({
                                    type: 'success',
                                    message: res.data.msg
                                });
                                this.$router.replace('/')
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: res.data.msg
                                });
                            }
                        })
                })
            },
            errorHandler() {
                return true
            },
            getUserInfo() {
                setTimeout(() => {
                    axios.get('/getUserInfo')
                        .then((res) => {
                            if (res.data.code === 1) {
                                this.user = res.data.data
                                this.userId=this.user.userId
                                this.userName = this.user.userName
                                this.companyName = this.user.companyName
                                this.companyAddress = this.user.companyAddress
                                this.companyPhone = this.user.companyPhone
                            }
                        })
                })
            },
            updateUserNameOrPassword() {
                this.updateUserNameOrPasswordDialogVisible = false
                setTimeout(() => {
                    axios.post('/updateUserNameOrPassword', {
                        userId:this.userId,
                        userName: this.userName,
                        newPassword: this.newPassword,
                        confirmPassword: this.affirmPassword
                    }).then((res) => {
                        if (res.data.code === 1) {
                            this.logout()
                            this.$message({
                                type: 'success',
                                message: res.data.msg
                            });
                        } else {
                            this.$message({
                                type: 'error',
                                message: res.data.msg
                            });
                        }
                    })
                })
            }, updateCompanyInformation() {
                this.updateCompanyInformationDialogVisible = false
                setTimeout(() => {
                    axios.post('/updateUserOther', {
                        id:this.userId,
                        companyName: this.companyName,
                        companyAddress: this.companyAddress,
                        companyPhone: this.companyPhone
                    }).then((res) => {
                        if (res.data.code === 1) {
                            this.getUserInfo()
                            this.$message({
                                type: 'success',
                                message: res.data.msg
                            });
                        } else {
                            this.$message({
                                type: 'error',
                                message: res.data.msg
                            });
                        }
                    })
                })
            }
        }
    };
</script>

<style>
    .layout {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: row;

    }

    .el-menu-item.is-active {
        background-color: #409EFF !important;
        color: #fff;

    span {
        color: #fff !important;
    }

    }

    .el-menu-vertical-demo:not(.el-menu--collapse) {
        width: 200px;
        min-height: 400px;
    }

    .menu {
        width: 200px;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .collapseMenu {
        width: 64px;
    }

    .el-menu {
        width: 100%;
        height: 100%;
    }

    .right-content {
        width: 100%;
        height: 100%;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
    }


    .header {
        width: 100%;
        height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #409EFF;
    }

    .systemName {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: #ffffff;
        font-size: 20px;
    }

    .user-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: fixed;
        right: 0;
        margin-right: 80px;
    }

    .el-icon-s-fold {
        margin-left: 5px;
        font-size: 30px;
        color: #ffffff;
    }

    .el-icon-s-unfold {
        margin-left: 5px;
        font-size: 30px;
        color: #ffffff;
    }

    .content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        /* overflow-y: scroll; */
        /*overflow-x: scroll;*/
    }

    .el-dropdown-link {
        cursor: pointer;
        color: #ffffff;
    }

    .el-icon-arrow-down {
        font-size: 12px;
    }

    .el-dropdown {
        margin-left: 20px;
    }
</style>
